//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      title: 'Page Not Found',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'The specified file was not found on this website. Please check the URL for mistakes and try again.',
        },
      ],
    }
  },
}
