//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { faGithub } from '@fortawesome/free-brands-svg-icons'
export default {
  data() {
    return {
      faGithub,
    }
  },
}
