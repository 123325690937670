//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export default {
  props: {
    utc: Boolean,
    interval: { type: Number, default: 60000 },
    format: { type: String, default: 'ddd, DD MMM YYYY HH:mm:ss Z' },
  },
  data() {
    return {
      relativeDate: null,
      timer: null,
    }
  },
  computed: {
    lastModified() {
      return dayjs(this.$config.DATE_GENERATED)
    },
  },
  created() {
    this.toRelativeDate()
    this.timer = setInterval(this.toRelativeDate, this.interval)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    toRelativeDate() {
      this.relativeDate = this.lastModified.fromNow()
    },
  },
}
