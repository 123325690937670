//
//
//
//
//
//
//
//
//
//
//
//

import { mdiMagnify } from '@mdi/js'
export default {
  data: () => ({ mdiMagnify }),
  computed: {
    search: {
      get() {
        return this.$route.query.search || ''
      },
      set(search) {
        this.$router.push({
          path: '/',
          query: { search: search && search !== '' ? search : undefined },
        })
      },
    },
  },
}
