//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      title: 'OOPSIE WOOPSIE!!',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'An unexpected error has occured',
        },
      ],
    }
  },
}
