//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
export default {
  data: () => ({
    utc: false,
    faMapMarker,
  }),
  methods: {
    shortHash: (value) => (value ? value.substring(0, 7) : null),
  },
}
